import {action, observable} from 'mobx-angular';
import {IUser} from '../model/user.model';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class UserStore {
  @observable user: IUser;
  @observable
  loaded: boolean;

  constructor() {
  }

  @action
  setUser(user: IUser) {
    console.log('Setting user: ', user);
    this.user = user;
  }

  @action
  changeUserPoints(points: number) {
    this.user.points = this.user.points + points;
  }

  @action
  changeUserReservedPoints(points: number) {
    this.user.reserved_points = this.user.reserved_points + points;
  }

  @action
  changeUsername(username: string) {
    this.user.username = username;
  }

}
