import * as Parse from 'parse';


export async function initParse() {
  try {
    Parse.initialize('Candy', 'ISdfhasdaisdjiASdiasdoas');
    (Parse as any).serverURL = 'https://app.hei-program.com/parse';
    (Parse as any).publicServerURL = 'https://app.hei-program.com/parse';
    Parse.CoreManager.set('IDEMPOTENCY', true);
    console.log("CHECK PARSE", Parse.User.current());
  }catch (e) {
    console.log('Parse init error:', e);
  }
}


