import {action, observable} from 'mobx-angular';
import {IUser} from '../model/user.model';
import {Injectable} from '@angular/core';
import {ICountry} from "../model/country.model";
import {ICity} from "../model/city.model";
import {IShop} from "../model/shop.model";
import {ICategory} from "../model/category.model";
import {IBrand} from "../model/brend.model";
import {IModel} from "../model/model.model";

@Injectable()
export class SettingsStore {
  @observable countries: ICountry[];
  @observable cities: ICity[];
  @observable shops: IShop[];
  @observable categories: ICategory[];
  @observable brands: IBrand[];
  @observable models: IModel[];

  @observable
  loaded: boolean;

  constructor() {
    this.countries = [];
    this.cities = [];
    this.shops = [];
    this.brands = [];
    this.categories = [];
    this.models = [];
  }
  @action
  setCountries(countries: ICountry[]){
    // this.countries.push(...countries);
    this.countries = countries;
  }

  @action
  addCountry(country: ICountry){
    this.countries.push(country);
  }
  @action
  setCities(cities: ICity[]){
    // this.cities.push(...cities);
    this.cities = cities;
  }

  @action
  addCity(city: ICity){
    this.cities.push(city);
  }

  @action
  setShops(shops: IShop[]){
    // this.shops.push(...shops);
    this.shops = shops;
  }
  @action
  addShop(shop: IShop){
    this.shops.push(shop);
  }

  @action
  setCategories(categories: ICategory[]){
    // this.shops.push(...shops);
    this.categories = categories;
  }
  @action
  addCategory(category: ICategory){
    this.categories.push(category);
  }

  @action
  setBrands(brands: IBrand[]){
    // this.shops.push(...shops);
    this.brands = brands;
  }

  @action
  addBrand(brand: IBrand){
    this.brands.push(brand);
  }

  @action
  setModels(models: IModel[]){
    // this.shops.push(...shops);
    this.models = models;
  }
  @action
  addModel(model: IModel){
    this.models.push(model);
  }


}
