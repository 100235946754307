import { Component } from '@angular/core';
import {Platform} from '@ionic/angular';
import {initParse} from './config/parse';
import {UserStore} from './store/user.store';
import {IUser} from './model/user.model';
import * as Parse from 'parse';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // public appPages = [
  //   { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
  //   { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
  //   { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
  //   { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
  //   { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
  //   { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  // ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(private platform: Platform, private userStore: UserStore
  ) {
    // window.screen.orientation.lock('portrait').then(r=>{
      this.initializeApp();
    // });
  }

  initializeApp() {
    console.log('platform: ', this.platform);
    initParse().finally(()=>{
      this.platform.ready().then(async () => {
        if(Parse.User.current()){
          this.userStore.setUser(Parse.User.current().toJSON() as unknown as IUser);
        }
      });
    });



  }
}
