import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guard/auth.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  // },
  // {
  //   path: 'register',
  //   loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule)
  // }
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule)
  },
  // {
  //   path: 'home',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: 'sold',
    loadChildren: () => import('./pages/sold/sold.module').then( m => m.SoldPageModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./modals/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'voucher',
    loadChildren: () => import('./pages/voucher/voucher.module').then( m => m.VoucherPageModule)
  },
  {
    path: 'get-voucher',
    loadChildren: () => import('./modals/get-voucher/get-voucher.module').then( m => m.GetVoucherPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'training',
    loadChildren: () => import('./pages/training/training.module').then( m => m.TrainingPageModule)
  },
  {
    path: 'lesson',
    loadChildren: () => import('./pages/lesson/lesson.module').then( m => m.LessonPageModule)
  },
  {
    path: 'question',
    loadChildren: () => import('./pages/question/question.module').then( m => m.QuestionPageModule)
  },
  {
    path: 'all-news',
    loadChildren: () => import('./pages/all-news/all-news.module').then( m => m.AllNewsPageModule)
  },  {
    path: 'voucher-history',
    loadChildren: () => import('./pages/voucher-history/voucher-history.module').then( m => m.VoucherHistoryPageModule)
  },
  {
    path: 'sold-history',
    loadChildren: () => import('./pages/sold-history/sold-history.module').then( m => m.SoldHistoryPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/auth/forgot/forgot.module').then( m => m.ForgotPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
